import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Row } from '@docs/helpers';
import { Playground } from 'docz';
import Badge from './badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Badges display status to users.`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Badge from '../../../ui/badge'
`}</code></pre>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <p>{`There's only `}<em parentName="p">{`one`}</em>{` type right now, a gray background with black text:`}</p>
    <Playground __position={0} __code={'<Row>\n  <Badge>Data Not Available</Badge>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Badge mdxType="Badge">Data Not Available</Badge>
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      